.nine_container {
    
    
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
   
}


.nine_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    padding-bottom: 0;
}

.nine_content h1 {
    background-image: linear-gradient(to right, #cb49b1, #937eec, #29a6ff, #00c3fb, #26d8e2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 88px;
    font-weight: 800;
    text-align: center;

    
}

.nine_content p {
    color: black;
    font-size: 36px;
    font-weight: 400;
    margin: 1em 5em 1.5em 5em;
    text-align: center;
    padding: 0 10vh 5vh 10vh;
}

.item-grid h4 {
    color: white;
    line-height: 1rem;
}

.item-grid p {
    color: white;
    padding: 0;
    margin-top: -10px;
    font-weight: 500;
}

.growth_hacking {
    max-width: 380px;
    margin: auto;
    margin-top: -50px;
}

.hosting {
    max-width: 450px;
    margin: auto;
    margin-top: -10px;
    
  
    
    
}

/* ----- GRID ----- */

.parent_grid {
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    margin: auto 6vw;
}


.item-grid {
    display: grid;
    height: 480px;
    padding: 30px 0 0 30px;
    border: 0.1px solid;
    border-radius: 20px;
    border-color: #ffffffc2;
    overflow: hidden;
    
}

.item-grid p {
    font-size: 22px;
    font-weight: 400;
    padding-right: 30px;
    padding-bottom: 0;
    color: white;
    margin-top: -10px;
    
}

.item-grid:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(166, 232, 248) 0px 0px 0px 8px;
    transition: box-shadow 0.3s ease-in-out;
}

.item-grid:nth-child(1) {
    grid-column: span 5;
    background-image: linear-gradient(to right top, #cdacec, #b792ed, #9b79ef, #7662f2, #344ef6);
    overflow: hidden;

    img {
        
        width: 100%; /* or any custom size */   
    }
}

.item-grid:nth-child(2) {
    grid-column: span 3;
    background-image: linear-gradient(to right top, #051937, #0e3e65, #0f6893, #0896c1, #12c6eb);
    display: grid;
    overflow: hidden;
    

    img {
        
        width: 350px;
        margin: auto;
        margin-top: -50px;

    }

    .slide-top:hover {
        -webkit-animation: slide-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: slide-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
    }
  }
 }

    
   


.item-grid:nth-child(3) {
    display: flex;
    flex-direction: column;
    grid-column: span 3;
    // background-image: linear-gradient(to top, #12c0db, #00cddc, #00dad8, #16e7d0, #49f2c5);  
    background-image: linear-gradient(to right top, #346edd, #505fd3, #654ec5, #753bb6, #8220a4);
    
   
}

.item-grid:nth-child(4) {
    background-image: linear-gradient(to left top, #ffffff, #e6ecff, #beddff, #84d1fa, #12c6eb);
    grid-column: span 5;
    }    

.item-grid:nth-child(5) {
    background-image: linear-gradient(to right top, #845ec2, #e65faa, #ff8080, #ffbb61, #f9f871);
    grid-column: span 4;

    
}

.item-grid:nth-child(6) {
    background-image: linear-gradient(to left top, #ffffff, #e6ecff, #beddff, #84d1fa, #12c6eb);
    grid-column: span 4;
    }    



@media only screen and (min-width: 501px) and (max-width: 1280px) {

    .parent_grid {
        // padding: 0 20vh;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 20px;
        // margin: auto 6vw;
    }
}


@media only screen and (min-width: 1281px) {



    .parent_grid {
        padding: 0 10%;
    }

    .item-grid p {
        font-size: 22px;
        font-weight: 400;
        padding-bottom: 0;
    }


    .mobile-only {
        display: none;
    }
}

@media only screen and (max-width: 500px) {

    
    .nine_content {
        margin-bottom: 0;
    }

    .nine_content p {
        color: black;
        font-size: 28px;
        font-weight: 400;
        padding: 5% 5%;
        margin: 5% 0;
    }

    .nine_content h1 {
        font-size: 60px;
        font-weight: 900;
        padding: 0;
        margin: 0;    
        
    }

    .parent_grid {
        grid-template-columns: repeat(auto-fill, 1fr);
        grid-template-rows: auto;
        margin: auto;
        padding: 0 5%;
    }

    .item-grid h4 {
        font-size: 28px;
        font-weight: 600;
    }

    .item-grid p {
        font-size: 22px;
        font-weight: 400;
        padding-bottom: 35px;
    }

    .growth_hacking {
        max-width: 320px;
    }

    .hosting {
        max-width: 450px;
        margin-top: -50px;
    }
    

    .desktop-only {
        display: none;
    }


    .item-grid:nth-child(1) {
         grid-column: span 8;
    }

    .item-grid:nth-child(2) {
        grid-column: span 8;
   }

    .item-grid:nth-child(3) {
         grid-column: span 8;

       
     }

     .item-grid:nth-child(4) {
        grid-column: span 8;
   }

   .item-grid:nth-child(5) {
    grid-column: span 8;
    

    img
    {

       width: 100%;

    }

}

.item-grid:nth-child(6) {
    grid-column: span 8;
}

}