.eight_container {
    
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
   
}


.eight_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.eight_content h1 {
    background-image: linear-gradient(to right, #fda407, #ff7a36, #ff4763, #ff0696, #ef1fcb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 88px;
    font-weight: 800;
    text-align: center;
}

.eight_content p {
    color: black;
    font-size: 36px;
    font-weight: 400;
    margin: 1em 5em 1.5em 5em;
    text-align: center;
    padding: 0 10vh;
}

.eight_templates {
  max-width: 1250px;  
  padding: 0 10vh;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;  
  text-align: center;

  p{
    padding: 0;
    margin-top: -20px;
    font-size: 18px;
  }

  h4{
    font-size: 28px;
    margin-top: 20px;
  }
}



.item {
    
    

    img {
        border: 0.1px solid;
        border-color: rgba(255, 255, 255, 0.842);
        border-radius: 10px;

       
       
    }

    img:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(166, 232, 248) 0px 0px 0px 8px;
        transition: box-shadow 0.3s ease-in-out;
        
    }
}

    /* ------ Button Style ------ */

.glow-on-hover2 {
    width: 350px;
    height: 75px;
    border: none;
    outline: none;
    color: #fff;
    background-image: linear-gradient(to right, #fda407, #ff7a36, #ff4763, #ff0696, #ef1fcb);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-family: 'Poppins', 'sans-serif';
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 100px;
}

.glow-on-hover2:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #F49867, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover2:active {
    color: white;
}

.glow-on-hover2:active:after {
    background: transparent;
}

.glow-on-hover2:hover:before {
    opacity: 1;
}

.glow-on-hover2:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #fda407, #ff7a36, #ff4763, #ff0696, #ef1fcb);
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}



@media only screen and (max-width: 500px) {


   

    .eight_templates {
        grid-template-columns: repeat(auto-fill, 1fr);
        grid-template-rows: auto;
        margin: auto;
        padding: 0 5%;
    }

    .item {
        grid-column: 3 span;
    }

    .eight_content p {
        color: black;
        font-size: 28px;
        font-weight: 400;
        padding: 5% 5%;
        margin: 5% 0;
    }

    .eight_content h1 {
        font-size: 60px;
        font-weight: 900;
        padding: 0;
        margin: 0;    
}

    .eight_templates h4 {
        font-size: 28px;
    }

  
    .glow-on-hover2 {
        font-size: 22px;
        width: 270px;
        height: 60px;
    }

}