.hero_section {
    min-height: 100%;
    
    
}

.hero_container {
    
	
    
}

.hero_first{
    box-sizing: border-box;
    flex-basis: 50%;
	// display: flex;
	
    
	
}

.hero_content {
    
    h1 {
  font-size: 3.7em;
  background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 4.2rem;
  font-weight: 700;
}

	h3 {
		font-size: 1.3rem;
		color: white;
		font-weight: 300;
	}



}

.hero_buttons {
	display: flex;

	
}

.hero_btn {
	border: 1px solid;
	border-color: rgba(255, 255, 255, 0.582);
	border-radius: 7px;
	color: white;
	max-width: 150px;
	text-align: center;
	padding: 10px 20px;
	background: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
	font-size: 16px;
	font-weight: 400;

}


.hero_btn2 {
	margin-left: 2rem;
	color: white;
	max-width: 150px;
	text-align: center;
	padding: 10px 5px;
	
	font-size: 16px;
	font-weight: 400;
}



.hero_toptext {
		margin-bottom: 15px;
		font-size: 14px;
		padding: 5px 15px;
		max-width: 250px;
		color: white;
		text-align: center;
		background: rgba( 255, 255, 255, 0.25 );
		backdrop-filter: blur( 4px );
		-webkit-backdrop-filter: blur( 4px );
		border-radius: 15px;
		border: 1px solid rgba( 255, 255, 255, 0.18 )
		
		
}

.hero_toptext:hover {
		box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
}



.hero_second {
    box-sizing: border-box;
    flex-basis: 50%;
    display: flex;
    justify-content: flex-end;
}


@media (min-width: 900px) {

    .hero_section {
        
    }

    .hero_container {
        display: flex;
        justify-content: space-between;
        padding-left: 16%;
        padding-right: 15%;
		padding-top: 5%;
		padding-top: 20vh;
    }
    
    .hero_content {
		padding-right: 10px;
	}

	.hero_first {

	}
}









/* Logo Slider */

.logo-slider {
	background: rgba(255, 255, 255, 0);
	-webkit-box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.728);
	box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
    padding-bottom: 50px;



}

@media (min-width: 900px) {
	.logo-slider {
		// width: 68%;
	}
}


.logo-slider::before, .logo-slider::after {
	background: -webkit-gradient(linear, left top, right top, from(rgba(4, 12, 24, 0.599)), to(rgba(4, 12, 24, 0.682)));
	background: linear-gradient(to right, rgba(4, 12, 24, 0) 0%, rgba(4, 12, 24, 0) 100%);
	content: "";
	height: 175px;
	position: absolute;
	width: 200px;
	z-index: 2;
}

.logo-slider::after {
	right: 0;
	top: 0;
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}

.logo-slider::before {
	left: 0;
	top: 0;
}

.logo-slider .logo-slide-track {
	-webkit-animation: logo-scroll 60s linear infinite;
	animation: logo-scroll 60s linear infinite;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: calc(250px * 14);
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.logo-slider .slide {
	height: 300px;
	width: 500px;
	padding: 20px;
}

@-webkit-keyframes logo-scroll {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(calc(-250px * 5));
		transform: translateX(calc(-250px * 5));
	}
}

@keyframes logo-scroll {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(calc(-250px * 5));
		transform: translateX(calc(-250px * 5));
	}
}