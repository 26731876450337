@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.section_eleven {
	

}

// .topbar {
// 	min-height: 3vh;
// 	background-color: yellow;
// 	position: relative;

// 	h2 {
		
// 		font-size: 20px;
// 		font-weight: 600;
// 		text-align: center;
// 	}
// }



.eleven_container {
    
    /* ------- BACKGROUND GRADIENT ------- */

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 50% 100%, rgb(4, 10, 14) 0%, rgb(1, 0, 3) 35%, rgba(11, 3, 46, 1) 60%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 100%, rgb(4, 10, 14) 0%, rgb(1, 0, 3) 35%, rgba(11, 3, 46, 1) 60%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 100%, rgb(4, 10, 14) 0%, rgb(1, 0, 3) 35%, rgba(11, 3, 46, 1) 60%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 100%, rgb(4, 10, 14) 0%, rgb(1, 0, 3) 35%, rgba(11, 3, 46, 1) 60%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 100%, rgb(4, 10, 14) 0%, rgb(1, 0, 3) 35%, rgba(11, 3, 46, 1) 60%);



    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
	overflow: hidden;
	position: relative;
   
}

.eleven_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 450px;
	min-height: 90vh;
    margin-bottom: 0;
    padding-bottom: 0;
	z-index: 2;
	background-image: linear-gradient(to top, #0b032e, #09053ae3, #03031e78, #01011a6e, #00000055);
}


.eleven_content h1 {
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 90px;
    font-weight: 800;
    text-align: center;
	font-family: 'Montserrat', sans-serif;
}

.eleven_content p {
    color: white;
    font-size: 36px;
    font-weight: 600;
	margin: 0 5em 1.5em 5em;
    text-align: center;
    padding: 0 20vh;
}


.website_section {
	position: absolute;
	z-index: 0;
	
}

/* Website Slider */

.website-slider {
	background: rgba(255, 255, 255, 0);
	-webkit-box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.728);
	box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
	margin: auto;
	position: relative;
	width: 100%;
    
}

.website {
    
   
   	//background-color: white;
    margin: 12px;
    padding: 0;
    box-shadow: 4px 3px 25px rgba(0, 0, 0, 0.1)  ; 
	-webkit-box-shadow: 4px 3px 25px rgba(0, 0, 0, 0.1)  ; 
	-moz-box-shadow: 4px 3px 25px rgba(0, 0, 0, 0.1)  ; 
    
	img {
		min-width: 500px;
	}
}



.website-slider::before, .website-slider::after {
	background: -webkit-gradient(linear, left top, right top, from(rgba(4, 12, 24, 0.599)), to(rgba(4, 12, 24, 0.682)));
	background: linear-gradient(to right, rgba(4, 12, 24, 0) 0%, rgba(4, 12, 24, 0) 100%);
	content: "";
	height: 175px;
	position: absolute;
	width: 200px;
	z-index: 2;
}

.website-slider::after {
	right: 0;
	top: 0;
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}

.website-slider::before {
	left: 0;
	top: 0;
}

.website-slider .website-slide-track {
	-webkit-animation: logo-scroll 60s linear infinite;
	animation: logo-scroll 60s linear infinite;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: calc(250px * 14);
  animation-duration: 120s;
  animation-iteration-count: infinite;
}

.website-slider .slide {
	 height: 300px;
	 width: 500px;
	
}





.website-slider2::before, .website-slider2::after {
	background: -webkit-gradient(linear, left top, right top, from(rgba(4, 12, 24, 0.599)), to(rgba(4, 12, 24, 0.682)));
	background: linear-gradient(to right, rgba(4, 12, 24, 0) 0%, rgba(4, 12, 24, 0) 100%);
	content: "";
	height: 175px;
	position: absolute;
	width: 200px;
	z-index: 2;
}

.website-slider2::after {
	right: 0;
	top: 0;
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}

.website-slider2::before {
	left: 0;
	top: 0;
}

.website-slider2 .website-slide-track2 {
	-webkit-animation: logo-scroll2 60s linear infinite;
	animation: logo-scroll2 60s linear infinite;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: calc(250px * 14);
  animation-duration: 120s;
  animation-iteration-count: infinite;
}

.website-slider .slide {
	 height: 300px;
	 width: 500px;
	
}

@-webkit-keyframes logo-scroll {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(calc(-250px * 8));
		transform: translateX(calc(-250px * 8));
	}
}

@keyframes logo-scroll {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(calc(-250px * 8));
		transform: translateX(calc(-250px * 8));
	}
}

@keyframes logo-scroll2 {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(calc(-250px * 12));
		transform: translateX(calc(-250px * 12));
	}
}

@-webkit-keyframes logo-scroll2 {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(calc(-250px * 15));
		transform: translateX(calc(-250px * 15));
	}
}


@media only screen and (min-width: 501px) and (max-width: 1280px) {


	.eleven_content {
		padding-top: 220px;
	}

	.eleven_content p {
        color: white;
        font-size: 32px;
        font-weight: 600;
        padding: 0 10vw;
        margin: 0 0 5% 0;
    }

	.eleven_content h1 {
		font-size: 70px;
	}

	

	.website {

		img {
			min-width: 400px;
		}
	}

	.website-slider .slide {
		height: 250px;
		width: 400px;
	   
   }

}


@media only screen and (max-width: 500px) {

	
    .eleven_content {
		padding-top: 200px;
	}

    .eleven_content p {
        color: white;
        font-size: 25px;
        font-weight: 500;
        padding: 5% 2%;
        margin: 5% 0;
    }

    .eleven_content h1 {
        font-size: 3.4rem;
        font-weight: 700;
		font-family: 'Poppins', sans-serif;
        padding: 2%;
        margin: 0;
        
    
    }

    .glow-on-hover3 {
        font-size: 22px;
        width: 270px;
        height: 60px;
    }


}

@media only screen and (max-width: 428px)
{ 
	.eleven_content h1 {
		font-size: 3.2rem;
		padding: 0;
	}
}
