@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.seven_container {
    
    /* ------- BACKGROUND GRADIENT ------- */

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 50% 100%, rgba(0, 172, 255, 1) 0%, rgba(16, 0, 143, 1) 35%, #0b032e 60%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50% 100%, rgba(0, 172, 255, 1) 0%, rgba(16, 0, 143, 1) 35%, rgba(11, 3, 46, 1) 60%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50% 100%, rgba(0, 172, 255, 1) 0%, rgba(16, 0, 143, 1) 35%, rgba(11, 3, 46, 1) 60%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50% 100%, rgba(0, 172, 255, 1) 0%, rgba(16, 0, 143, 1) 35%, rgba(11, 3, 46, 1) 60%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50% 100%, rgba(0, 172, 255, 1) 0%, rgba(16, 0, 143, 1) 35%, rgba(11, 3, 46, 1) 60%);



    width: 100%;
    min-height: max-content;
    display: flex;
    flex-direction: column;
   
}


.seven_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 0;
    padding-bottom: 0;
}


.seven_content h1 {
    background: linear-gradient(to right, #60a2f5, #4db8fb, #50cbfc, #69ddf9, #8aedf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 100px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
}

.seven_content p {
    color: white;
    font-size: 36px;
    font-weight: 400;
    margin: 1em 5em 1.5em 5em;
    text-align: center;
    padding: 0 10vh;
}



.seven_image {

    display: flex;
    justify-content: center;
    
    align-items: center;



    img {
       width: 70%;
      
    }
}








/* ------ Button Style ------ */

.glow-on-hover {
    width: 350px;
    height: 75px;
    border: none;
    outline: none;
    color: #fff;
    background: linear-gradient(to right, #60a2f5, #4db8fb, #50cbfc, #69ddf9, #8aedf6);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-family: 'Poppins', 'sans-serif';
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 100px;
    
    a {
        color: white;
    }
   
}





        .glow-on-hover:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
        }

        .glow-on-hover:active {
        color: white;
        }

        .glow-on-hover:active:after {
        background: transparent;
        }

        .glow-on-hover:hover:before {
        opacity: 1;
        }

        .glow-on-hover:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #60a2f5, #4db8fb, #50cbfc, #69ddf9, #8aedf6);
        left: 0;
        top: 0;
        border-radius: 10px;
        }


/* ------ Button Style ------ */

.glow-on-hover3 {
        width: 350px;
        height: 75px;
        border: none;
        outline: none;
        color: #fff;
        background-image: linear-gradient(to left bottom, #346edd, #505fd3, #654ec5, #753bb6, #8220a4);
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
        font-family: 'Poppins', 'sans-serif';
        font-size: 30px;
        font-weight: 900;
        margin-bottom: 100px;
        
        a {
            color: white;
        }
       
    }
    
    
    
    
    
            .glow-on-hover3:before {
            content: '';
            background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
            position: absolute;
            top: -2px;
            left:-2px;
            background-size: 400%;
            z-index: -1;
            filter: blur(5px);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            animation: glowing 20s linear infinite;
            opacity: 0;
            transition: opacity .3s ease-in-out;
            border-radius: 10px;
            }
    
            .glow-on-hover3:active {
            color: white;
            }
    
            .glow-on-hover3:active:after {
            background: transparent;
            }
    
            .glow-on-hover3:hover:before {
            opacity: 1;
            }
    
            .glow-on-hover3:after {
            z-index: -1;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to left bottom, #346edd, #505fd3, #654ec5, #753bb6, #8220a4);
            left: 0;
            top: 0;
            border-radius: 10px;
            }
    
        

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.bounce-top {
	-webkit-animation: bounce-top 0.9s both;
	        animation: bounce-top 0.9s both;
}


 @-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  


@media only screen and (min-width: 1281px) {

    .mobile-only {
        display: none;
    }
}






@media only screen and (min-width: 501px) and (max-width: 1280px) {
        .mobile-only {
           display: none;
        }
   

        .seven_content h1 {
           font-size: 85px;
        }

//         .seven_content p {
//            padding: 0;
//            margin: 0 0 5% 0;
//        }
   }


   @media only screen and (max-width: 500px) {

    .desktop-only {
        display: none;
    }

    .seven_container {
        min-height: max-content‚;
    }
    .seven_image {

        display: flex;
        justify-content: center;
        align-items: center;
    
    
    
        img {
           width: 90%;
          
        }
    }

    .seven_content p {
        color: white;
        font-size: 25px;
        font-weight: 400;
        padding: 5%;
        margin: 5% 0;
    }

    .seven_content h1 {
        font-size: 3.4rem;
        font-weight: 700;
        padding: 0;
        margin: 0;
        
    
    }

    .glow-on-hover {
        font-size: 22px;
        font-weight: 700;
        width: 270px;
        height: 60px;

    }

}