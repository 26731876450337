// .nine_container {
    
    
//     width: 100%;
//     min-height: 100vh;
//     display: flex;
//     flex-direction: column;
// 	margin-bottom: 10vh;
// }

.ten_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.ten_content h1 {
    background: linear-gradient(to right, #60a2f5, #4db8fb, #50cbfc, #69ddf9, #8aedf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	font-family: 'Montserrat', sans-serif;
    font-size: 88px;
    font-weight: 700;
    text-align: center;

    
}

.ten_content p {
	color: black;
    font-size: 36px;
    font-weight: 400;
    margin: 1em 5em 1.5em 5em;
    text-align: center;
    padding: 0 10vh;
}


.testimonial {
    
    min-width: 360px;
    max-width: 370px;
    background-color: white;
    margin: 20px 20px;
    padding: 20px 20px;
    border: 1px solid;
    border-color: white;
    border-radius: 25px;
    box-shadow: 4px 3px 25px rgba(0, 0, 0, 0.1)  ; 
	-webkit-box-shadow: 4px 3px 25px rgba(0, 0, 0, 0.1)  ; 
	-moz-box-shadow: 4px 3px 25px rgba(0, 0, 0, 0.1)  ; 
    

}

.test_user {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	


	img {
		width: 40px;
		height: 40px;
	}
}

.img_stars {
	width: 80px;
	height: 16px;
}

.test_user h4 {
	font-size: 22px;
	margin: auto 10px;
}

.testimonial_text {
	font-size: 18px;
	color: rgb(0, 0, 0);
}

.company_name {
	font-size: 14px;
	font-weight: 600;
	color: rgb(29, 29, 29);
	margin: 0;
	padding: 0;
}

.testimonial_section {
	display: flex;
	width: 100%;
	height: 100%; 
	max-width: 100%; 
	max-height: 100%; 
	place-items: center; 
	margin: 0px; 
	padding: 0px; 
	list-style-type: none; 
	opacity: 1; 
	-webkit-mask-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 25%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%); overflow: hidden;
	
}





/* Logo Slider 2*/

.testimonial-slider2 {
	background: rgb(255, 255, 255);
	box-shadow: inset -4px 29px 100px 32px rgb(255, 255, 255);
	margin: auto;
	overflow: hidden;
	position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
	width: 100%;
    max-height: 80vh;
    padding-bottom: 10px;
  
    



}

@media (min-width: 900px) {
	.testimonal-slider2 {
		// width: 68%;
	}
}


.testimonial-slider2::before, .testimonial-slider2::after {
    background-image: -webkit-gradient(linear, left top, right top, from(rgb(255, 255, 255)), to(rgba(255, 255, 255, 0.874)));
 	background-image: linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.833) 100%);
	content: "";
	position: absolute;
	z-index: 2;
}

.testimonial-slider2::after {
	right: 0;
	top: 0;
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}

.testimonial-slider2::before {
	left: 0;
	top: 0;
}

.testimonial-slider2 .testimonial-slide-track2 {
	-webkit-animation: testimonial-scroll2 360s linear infinite;
	animation: testimonial-scroll2 360s linear infinite;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    flex-direction: column;
	height: calc(250px * 20);
  animation-duration: 45s;
  animation-iteration-count: infinite;
}


 


@-webkit-keyframes testimonial-scroll2 {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(calc(-250px * 2.5));
		transform: translateY(calc(-250px * 2.5));
	}
}

@keyframes testimonial-scroll2 {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(calc(-250px * 2.5));
		transform: translateY(calc(-250px * 2.5));
	}
}



/* Logo Slider 3*/

.testimonial-slider3 {
	box-shadow: inset 0px 29px 100px 0px rgba(255,255,255,0.6);
	margin: auto;
	overflow: hidden;
	position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
	width: 100%;
    max-height: 80vh;
    padding-bottom: 10px;
  
    



}



.testimonial-slider2::before, .testimonial-slider2::after {
	background-image: -webkit-gradient(linear, left top, right top, from(rgb(255, 255, 255)), to(rgba(255, 255, 255, 0.903)));
	background-image: linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.93) 100%);
	content: "";
	position: absolute;
	z-index: 2;
}

.testimonial-slider2::after {
	right: 0;
	top: 0;
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
}

.testimonial-slider2::before {
	left: 0;
	top: 0;
}

.testimonial-slider2 .testimonial-slide-track3 {
	-webkit-animation: testimonial-scroll3 360s linear infinite;
	animation: testimonial-scroll3 360s linear infinite;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    flex-direction: column;
	height: calc(250px * 14);
  animation-duration: 45s;
  animation-iteration-count: infinite;
}


 


@-webkit-keyframes testimonial-scroll3 {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(calc(-150px * 2.5));
		transform: translateY(calc(-150px * 2.5));
	}
}

@keyframes testimonial-scroll3 {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(calc(-150px * 2.5));
		transform: translateY(calc(-150px * 2.5));
	}
}



@media only screen and (max-width: 500px) {
	

	.desktop_only {
		display: none;
	}

	.ten_content {
		margin-top: 150px;
	}

	.ten_content p {
		color: black;
        font-size: 28px;
        font-weight: 400;
        padding: 0 5% 5% 5%;
        margin: 0 0 5% 0;
    }

    .ten_content h1 {
        font-size: 60px;
        font-weight: 700;
        
    }



}